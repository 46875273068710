@import url('ag-grid-community/styles/ag-grid.css');
@import url('ag-grid-community/styles/ag-theme-quartz.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-Bold.otf') format('opentype'),
        url('../assets/fonts/Marianne-Bold.woff') format('woff'),
        url('../assets/fonts/Marianne-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-BoldItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-Bold_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-Bold_Italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-ExtraBoldItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-ExtraBold_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-ExtraBold_Italic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-LightItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-Light_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-Light_Italic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-Light.otf') format('opentype'),
        url('../assets/fonts/Marianne-Light.woff') format('woff'),
        url('../assets/fonts/Marianne-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-MediumItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-Medium_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-Medium_Italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-Medium.otf') format('opentype'),
        url('../assets/fonts/Marianne-Medium.woff') format('woff'),
        url('../assets/fonts/Marianne-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-Regular.otf') format('opentype'),
        url('../assets/fonts/Marianne-Regular.woff') format('woff'),
        url('../assets/fonts/Marianne-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-RegularItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-Regular_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-Regular_Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-ThinItalic.otf') format('opentype'),
        url('../assets/fonts/Marianne-Thin_Italic.woff') format('woff'),
        url('../assets/fonts/Marianne-Thin_Italic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne';
    src:
        local('Marianne'),
        url('../assets/fonts/Marianne-Thin.otf') format('opentype'),
        url('../assets/fonts/Marianne-Thin.woff') format('woff'),
        url('../assets/fonts/Marianne-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

* {
    font-family: 'Marianne', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

html {
    line-height: 1.5;
}

.overflow-anywhere {
    overflow-wrap: anywhere;
}

html,
body {
    height: 100%;
    line-height: 1.5;
}
body {
    margin: 0;
    font-family: Marianne, 'Marianne', sans-serif;
    background-color: #f8fafc;
}

button {
    border-radius: 5px !important;
}

.mdc-text-field {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
    background-color: #f7fcfa !important;

    div[matformfieldlineripple] {
        display: none;
    }
}

input::placeholder {
    opacity: 1 !important;
    color: #cdcdcd !important;
}

textarea::placeholder {
    opacity: 1 !important;
    color: #cdcdcd !important;
}

mat-icon {
    vertical-align: top;
}

.mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #133f70 !important;
}

.mat-mdc-form-field-icon-suffix {
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#paginatorDatagrid {
    background: none;
}

.ag-row.childGrid > [role='gridcell'] {
    padding-left: 0;
    padding-right: 0;
}

.NgxEditor ul,
ol {
    padding-left: 3rem;
}
.NgxEditor ul li {
    list-style-type: disc;
}

.NgxEditor ol li {
    list-style-type: decimal;
}

.NgxEditor h1 {
    font-size: 2em;
    color: #333;
    font-weight: bold;
    margin: 0.67em 0;
}

.NgxEditor h2 {
    font-size: 1.5em;
    color: #444;
    font-weight: bold;
    margin: 0.75em 0;
}

.NgxEditor h3 {
    font-size: 1.17em;
    color: #555;
    font-weight: bold;
    margin: 0.83em 0;
}

.NgxEditor h4 {
    font-size: 1em;
    color: #666;
    font-weight: bold;
    margin: 1.12em 0;
}

.NgxEditor h5 {
    font-size: 0.83em;
    color: #777;
    font-weight: bold;
    margin: 1.5em 0;
}

.NgxEditor h6 {
    font-size: 0.67em;
    color: #888;
    font-weight: bold;
    margin: 1.67em 0;
}

.NgxEditor a {
    font-size: 1em;
    color: #0000cd;
    text-decoration: underline;
}
.NgxEditor p {
    font-size: 1em;
}

.max-content {
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1rem;
}

.mat-mdc-menu-item-text{
    font-family: 'Marianne', sans-serif !important;
}
.mat-mdc-menu-content{
    padding: 0!important;
}
.mat-mdc-menu-panel {
    max-width: 1000px !important;
    background-color: #f8fafc !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}
.red-snackbar > .mdc-snackbar__surface {
    background: #bd1d1d !important;
}
.snackbar .mat-mdc-snack-bar-action {
    color: #fff !important;
}
.green-snackbar > .mdc-snackbar__surface {
    background: rgb(54, 143, 139) !important;
}

.long-form-field-container .long-form-field {
    height: auto !important;
}