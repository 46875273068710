@use 'sass:map';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$_palettes: (
    primary: (
        0: #000000,
        10: #00201f,
        20: #003735,
        25: #004341,
        30: #00504d,
        35: #005d5a,
        40: #006a66,
        50: #008581,
        60: #00a29d,
        70: #22beb9,
        80: #4edad4,
        90: #70f7f1,
        95: #b0fffa,
        98: #e3fffc,
        99: #f2fffd,
        100: #ffffff,
    ),
    secondary: (
        0: #000000,
        10: #001c3a,
        20: #00315f,
        25: #003c72,
        30: #004786,
        35: #055399,
        40: #1f5fa6,
        50: #3f78c1,
        60: #5c92dc,
        70: #78adf9,
        80: #a6c8ff,
        90: #d4e3ff,
        95: #ebf1ff,
        98: #f9f9ff,
        99: #fdfcff,
        100: #ffffff,
    ),
    tertiary: (
        0: #000000,
        10: #001c37,
        20: #003259,
        25: #003d6b,
        30: #00497e,
        35: #005591,
        40: #0861a4,
        50: #347abe,
        60: #5394da,
        70: #6faff7,
        80: #9fcaff,
        90: #d2e4ff,
        95: #eaf1ff,
        98: #f8f9ff,
        99: #fdfcff,
        100: #ffffff,
    ),
    neutral: (
        0: #000000,
        4: #000c16,
        6: #001120,
        10: #001d36,
        12: #00213d,
        17: #002c4e,
        20: #003258,
        22: #00365f,
        24: #003b66,
        25: #003d6a,
        30: #00497d,
        35: #005590,
        40: #0361a3,
        50: #327abe,
        60: #5194da,
        70: #6eaff6,
        80: #9ecaff,
        87: #c2dcff,
        90: #d1e4ff,
        92: #dbe9ff,
        94: #e5eeff,
        95: #eaf1ff,
        96: #eff4ff,
        98: #f8f9ff,
        99: #fdfcff,
        100: #ffffff,
    ),
    neutral-variant: (
        0: #000000,
        10: #141d1d,
        20: #293231,
        25: #343d3c,
        30: #3f4948,
        35: #4a5453,
        40: #56605f,
        50: #6f7978,
        60: #889391,
        70: #a3adac,
        80: #bec9c7,
        90: #dae5e3,
        95: #e8f3f1,
        98: #f1fbfa,
        99: #f4fefd,
        100: #ffffff,
    ),
    error: (
        0: #000000,
        10: #410002,
        20: #690005,
        25: #7e0007,
        30: #93000a,
        35: #a80710,
        40: #ba1a1a,
        50: #de3730,
        60: #ff5449,
        70: #ff897d,
        80: #ffb4ab,
        90: #ffdad6,
        95: #ffedea,
        98: #fff8f7,
        99: #fffbff,
        100: #ffffff,
    ),
);

$_rest: (
    secondary: map.get($_palettes, secondary),
    neutral: map.get($_palettes, neutral),
    neutral-variant: map.get($_palettes, neutral-variant),
    error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

// Define the theme object.
$vetichange-front-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: $_primary,
            tertiary: $_tertiary,
        ),
        density: (
            scale: 0,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($vetichange-front-theme);
    --grey: #f1f5f9;
}

.mdc-checkbox {
    .mdc-checkbox__background {
        border: 1px solid #e6e6e6;
        background-color: #f7fcfa;
    }
}

.mat-mdc-chip {
    background-color: #f1f5f9 !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary:before {
    border: none !important;
}

.mat-tree {
    background-color: white !important;
}

.mat-mdc-card-header {
    padding: 0 !important;
}

.mat-mdc-select-arrow {
    display: none !important;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
    display: none !important;
}

.mdc-card__actions {
    min-height: auto !important;
}

.mat-drawer-content {
    display: flex !important;
    flex-direction: column;
}

.mat-mdc-form-field {
    height: 50px !important;
}
.mat-mdc-form-field.area-form-field {
    height: auto !important;
}

.mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mat-mdc-dialog-panel {
    width: fit-content !important;
    min-width: 40% !important;
    max-width: 100% !important;
}

.mat-mdc-dialog-surface {
    border-radius: 12px !important;
}
